import React from "react"
import { FaPhoneAlt } from "react-icons/fa"
import { BsGeo, BsEnvelope } from "react-icons/bs"

export default [
  {
    title: "Toll Free",
    info: "802.524.9546",
    extra:
      "No fee for initial consultation on personal injury and wrongful death claims, go to extension 15",
    icon: <FaPhoneAlt />,
  },
  {
    title: "Our Address",
    info: "2 North Main Street, Saint Albans, Vermont",
    icon: <BsGeo />,
  },
  {
    title: "Working Hours",
    info: "Mon - Fri : 8:00 am to 5:00 pm",
    icon: <BsEnvelope />,
  },
]

import React from "react"
import { createGlobalStyle } from "styled-components"
import { GatsbyProvider } from "./src/context/context"

const GlobalStyle = createGlobalStyle`
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: #003367;
  --clr-primary-2: #00526F;
  --clr-primary-3: #194b8c;
  --clr-primary-4: #174684;
  --clr-primary-5: #007bff;

  /* primary color */
  --clr-primary-6: #b69d74;
  --clr-primary-7: #b59158;
  --clr-primary-8: #263043;
  --clr-primary-9: #1f2839;

  /* dark shades of secondarycolor*/
  --clr-secondary-1: #e0fbfc;

  /* light shades of primary color*/
  --clr-primary-light-1: #168aad;
  --clr-primary-light-2: #1a759f;

  /* darkest grey - used for headings */
  --clr-grey-1: #333334;
  --clr-grey-2: #f9f9f9;
  --clr-grey-3: #4b4b4b;
  --clr-grey-4: #343a40;
  /* grey used for paragraphs */
  --clr-grey-5: #dae0e5;

  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: #177433;
  --clr-green-light: #28a745;
  --clr-black: #222;
  --ff-primary: "Cormorant Garamond", sans-serif;
  --ff-secondary: "Poppins", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;

/* TRANSITION */
  --transition: all 0.3s linear;

  --alpha: 0.8;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  height: 100%;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--clr-primary-6);
  ${"" /* color: var(--clr-green-light); */}
}

a:hover {
  text-decoration: none;
  color: var(--clr-primary-7);
  ${"" /* color: var(--clr-green-dark); */}
}

img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
  font-weight: 400;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 2.8rem;
  color: var(--clr-grey-3);
}

p:not(:last-child) {
  margin-bottom: 28px;
}

@media screen and (max-width: 480px) {
  p {
    font-size: 1.1rem;
  }

  h2 {
      font-size: 2.1rem;
  }

  h3 {
      font-size: 1.7rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  a {
      font-size: 1.1rem;
  }
}

.btn.btn-primary {
  border-radius: 1rem;
}

.btn-primary.custom-button {
  color: var(--clr-white);
  background-color: var(--clr-primary-6);
  border-color: var(--clr-primary-6);
}

.btn-primary.custom-button:hover {
  color: var(--clr-white);
  background-color: var(--clr-primary-7);
  border-color: var(--clr-primary-7);
}

.primary-custom-button:hover {
  transform: translate(0,-2px);
  color: var(--clr-white);
  background-color: var(--clr-primary-7);
}

.secondary-custom-button {
  color: var(--clr-white);
  background-color: var(--clr-primary-light-1);
  border-color: var(--clr-primary-4);
}

.secondary-custom-button:hover {
  transform: translate(0,-2px);
  color: var(--clr-white);
  background-color: var(--clr-primary-light-2);
  border-color: var(--clr-primary-4);
}

[class*="page-section"] {
    padding: 112px 0;
}

.section-title::after {
  content: "";
  display: block;
  position: relative;
  height: 3px;
  width: 30px;
  background-color: var(--clr-primary-1);
  margin: 0 auto;
  margin-top: 2.8rem;
}

.section-title:not(:last-child) {
  margin-bottom: 41px;
}

.page-header {
    ${"" /* margin-top: 9rem; */}
  background: var(--clr-primary-8);
}

.page-intro {
    margin-bottom: 8rem;
    font-weight: 900;
    text-align: center;
    text-transform: none;
    font-size: 2rem;
    line-height: 1.5;

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

.section-intro {
    margin-bottom: 8rem;
    font-weight: 900;
    text-transform: none;

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
}

.page-header-title {
  color: var(--clr-primary-6);
  margin-bottom: 2rem;
}

${
  "" /* 
@media screen and (max-width: 600px) {
  .page-header {
    position: relative;
    margin-top: 4rem;
  }
}


@media screen and (min-width: 800px) {
  .page-header {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 800px) {
  .page-header {
    margin-top: 4rem;
  }
}

@media screen and (min-width: 1200px) {
  .page-header {
    margin-top: 7rem;
  }
} */
}


@media screen and (min-width: 800px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */
/* section */
.section {
  padding: 5rem 0;
  position: relative;
}

.section-center {
  margin: 0 auto;
}

.section-heading {
  text-align: center;
  color: var(--clr-primary-6);
}

.section-heading::after {
    content: "";
    display: block;
    position: relative;
    height: 3px;
    width: 30px;
    background-color: var(--clr-primary-6);
    margin: 40px auto;
}

.section-background {
  background: var(--clr-grey-1);
}

.section-background-image {
      /* MUST!!!!!! */
    min-height: 50vh;
}

.section-background-light {
  background: var(--clr-grey-2);
}

.template__text-body {
  font-size: 1rem;
}

.template__text-body ol {
  font-family: var(--ff-secondary);
}
`
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      <GatsbyProvider>{element}</GatsbyProvider>
    </>
  )
}

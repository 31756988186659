import React from "react"
import { FaEnvelopeOpenText } from "react-icons/fa"
import { AiFillHome } from "react-icons/ai"
import { IoMdHelpCircle, IoMdPeople } from "react-icons/io"

export default [
  {
    title: "home",
    path: "/",
    icon: <AiFillHome />,
  },
  {
    title: "about",
    path: "/about",
    icon: <IoMdPeople />,
  },
  {
    title: "attorneys",
    path: "/attorneys/",
    icon: <IoMdPeople />,
  },
  {
    title: "practice areas",
    path: "/practice-areas",
    icon: <AiFillHome />,
  },
  {
    title: "FAQ",
    path: "/faq",
    icon: <IoMdHelpCircle />,
  },
  {
    title: "contact",
    path: "/contact",
    icon: <FaEnvelopeOpenText />,
  },
]

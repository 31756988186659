// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attorneys-js": () => import("./../../../src/pages/attorneys.js" /* webpackChunkName: "component---src-pages-attorneys-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-templetes-pratice-areas-templete-js": () => import("./../../../src/templetes/pratice-areas-templete.js" /* webpackChunkName: "component---src-templetes-pratice-areas-templete-js" */),
  "component---src-templetes-profile-templete-js": () => import("./../../../src/templetes/profile-templete.js" /* webpackChunkName: "component---src-templetes-profile-templete-js" */)
}

